// @ts-nocheck

import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import { Button, Card, CardContent, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CardActions from "@mui/material/CardActions";
import DataDisplayM from "../../../../Helpers/DataDisplayM/DataDisplayM";
import { log } from "../../../../../Services/LoggerService";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function CollectionReportView({ data = null }) {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // ui handlers
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
    }

    // hooks
    useEffect(() => {

    }, []);


    return (
        <div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal} sx={{ maxHeight: '80vh', overflowY: 'auto' }}>
                        <Card sx={{ minWidth: '75vw' }}>
                            <CardContent>
                                <div className="py-4 px-md-4 px-0">
                                    <h1 className="mb-3">{t('common.recordDetails')}</h1>
                                    <div className="row full-width">
                                        <h4 className="mt-3">Debtor Details</h4>
                                    </div>
                                    <div className="row full-width">
                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Account Name' data={data?.accountName} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='ID Type' data={data?.idType} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='ID Number' data={data?.id_number} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Registration Number' data={data?.registraction_number} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Customer Entity Name' data={data?.cus_entity_name} />
                                            }                                        </div>
                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='First Name' data={data?.firstName} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Surname' data={data?.surName} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Birth Date' data={data?.birthDt} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Salary Payment Day' data={data?.salary_payment_day} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Customer Name' data={data?.customerName} />
                                            }                                        </div>
                                    </div>


                                    <div className="row full-width">
                                        <h4 className="mt-3">Account Details</h4>
                                    </div>

                                    <div className="row full-width">
                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Account Status' data={data?.account_status} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Customer Legal Status' data={data?.legalStatus} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Account Last Status Date' data={data?.account_last_status_dt} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Account Last Balance' data={data?.account_last_balance} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Account Previous Balance' data={data?.account_previous_balance} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Payment Method" data={data?.paymentMethod} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Payment Flag" data={data?.payment_flag} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Account Credit" data={data?.account_credit} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Customer Category" data={data?.category} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM
                                                    label="Customer Account Number"
                                                    data={data?.customerAccountNum}
                                                />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Customer Segment" data={data?.segment} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM
                                                    label="Account Sub Status Code"
                                                    data={data?.acc_sub_status_code}
                                                />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="MTN Account Status" data={data?.mtn_acc_status} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM
                                                    label="MTN Account Sub Status"
                                                    data={data?.mtn_acc_sub_status}
                                                />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM
                                                    label="Termination Status"
                                                    data={data?.termination_status}
                                                />
                                            )}
                                        </div>

                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Fraud Status" data={data?.fraud_status} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Fraud Message" data={data?.fraud_message} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Risk Group" data={data?.risk_group} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Writeoff Amount" data={data?.write_off_amount} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="MTN App Status" data={data?.mtn_app_status} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Black Listing Status" data={data?.black_listing_status} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Arrears Amount" data={data?.treatmentBreachAmnt} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Store Name" data={data?.store_name} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Brc Channel" data={data?.brc_channel} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Arrear Amount" data={data?.treatmentBreachAmnt} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Total Account" data={data?.tot_accnt} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Total Number Arrear" data={data?.tot_num_arr} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="MTN Status'n" data={data?.mtn_status} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Number of Service Requests" data={data?.no_of_service_requests} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Number of Open Service Requests" data={data?.no_of_open_service_requests} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Number of Closed Service Requests" data={data?.no_of_closed_service_requests} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="SR Area" data={data?.sr_area} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="SR Status" data={data?.sr_status} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="SR Subarea" data={data?.sr_subarea} />
                                            )}
                                        </div>


                                    </div>

                                    <div className="row full-width">
                                        <h4 className="mt-3">MSISDN Details</h4>
                                    </div>

                                    <div className="row full-width">
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Last Msisdn Status Date" data={data?.last_msisdn_status_date} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="MSISDN" data={data?.msisdn} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="MSISDN Count" data={data?.msisdn_cnt} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="MSISDN Status" data={data?.msisdn_status} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="SIM Only Flag" data={data?.sim_only_flag ? 'Yes' : 'No'} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Active Date" data={data?.activeDt} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Package Code" data={data?.package_code} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Package Name" data={data?.package_name} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Price Plan Group" data={data?.price_plan_group} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Contract Start Date" data={data?.contractStartDt} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Contract End Date" data={data?.contractEndDt} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Out of Contract Flag" data={data?.out_of_contract_flag ? 'Yes' : 'No'} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Device Type" data={data?.device_type} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Last Active Use Date" data={data?.last_active_use_date} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Last Active Use Type" data={data?.last_active_use_type} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Last Recharge Date" data={data?.last_recharge_date} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Last Recharge Type" data={data?.last_recharge_type} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Last Usage Status" data={data?.last_usage_status} />
                                            )}
                                        </div>

                                    </div>

                                    <div className="row full-width">
                                        <h4 className="mt-3">Payment Details</h4>
                                    </div>

                                    <div className="row full-width">
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Last Payment Date" data={data?.lastPaymentDt} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Last Invoice Date" data={data?.last_invoice_date} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Last Invoice Amount" data={data?.last_invoice_amount} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Payment Due Date" data={data?.payment_due_date} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Balance Date" data={data?.balanceDate} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Last Amount Paid" data={data?.lastPaymentAmt} />
                                            )}
                                        </div>


                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Payment Day" data={data?.payment_day} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Last Rejected Date" data={data?.last_rejected_date} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Number of Payments" data={data?.number_of_payments} />
                                            )}
                                        </div>

                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Debicheck/RMs Processing Flag" data={data?.debicheck_rms_processing_flag} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Debicheck/RMs Suspension Flag" data={data?.debicheck_rms_suspension_flag} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Debicheck/RMs Authorized Flag" data={data?.debicheck_rms_authorized_flag} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Debit Order Date" data={data?.debit_order_date} />
                                            )}
                                        </div>

                                    </div>

                                    <div className="row full-width">
                                        <h4 className="mt-3">Ageing Details</h4>
                                    </div>
                                    <div className="row full-width">
                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Days 1-30' data={data?.conv30Days} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Days 31-60' data={data?.conv60Days} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Days 61-90' data={data?.conv90Days} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Days 91-120' data={data?.conv120Days} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Days 121-150' data={data?.conv150Days} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Days 151-180' data={data?.conv180Days} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Days Greater > 180' data={data?.convMoreThan180Days} />
                                            }                                        </div>

                                        <div className="col-md-3">
                                            {data &&
                                                <DataDisplayM label='Age Days' data={data?.ageDays} />
                                            }                                        </div>
                                    </div>

                                    <div className="row full-width">
                                        <h4 className="mt-3">Contact Details</h4>
                                    </div>

                                    <div className="row full-width">
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Preferred Contact Method" data={data?.preferred_contact_method} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Authorizer Phone #" data={data?.authorizer_phone_no} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="X Ctel-1" data={data?.cTel3} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="X Ctel-2" data={data?.cTel4} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="X Htel-1" data={data?.hTel} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="X Wtel-1" data={data?.wTel} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Email ID" data={data?.email1} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Physical Address" data={data?.physical_address} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Home Address" data={data?.home_address} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Billing Address" data={data?.billing_address} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Work Address" data={data?.work_address} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="WhatsApp Status" data={data?.whatsApp_status} />
                                            )}
                                        </div>

                                    </div>


                                    <div className="row full-width">
                                        <h4 className="mt-3">Collection Information</h4>
                                    </div>
                                    <div className="row full-width">
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Last Query Step" data={data?.last_query_step} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Last Query Date" data={data?.last_query_date} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Last Query Open Date" data={data?.last_query_open_date} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Last Query Close Date" data={data?.last_query_close_date} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Collection Status" data={data?.collection_status} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Collection Strategy Event Status" data={data?.collection_strategy_event_status} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Collection Strategy" data={data?.collection_strategy} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Best Day" data={data?.best_day} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Pay Date Range Start" data={data?.pay_date_ranges_start} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Pay Date Range End" data={data?.pay_date_range_end} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Pred Available Instalment" data={data?.pred_available_instalment} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="ES RANK" data={data?.es_rank} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="LS RANK" data={data?.ls_rank} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Date entered" data={data?.date_entered} />
                                            )}
                                        </div>

                                    </div>

                                    <div className="row full-width">
                                        <h4 className="mt-3">Account Links</h4>
                                    </div>
                                    <div className="row full-width">
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Payment Link" data={data?.payment_link} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="Balance Link" data={data?.balance_link} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="WhatsApp Link" data={data?.whatsApp_link} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="App Link" data={data?.app_link} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="PTP Payment Link" data={data?.ptp_payment_link} />
                                            )}
                                        </div>
                                    </div>

                                    <div className="row full-width">
                                        <h4 className="mt-3">General Information</h4>
                                    </div>
                                    <div className="row full-width">
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 1" data={data?.general_1} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 2" data={data?.general_2} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 3" data={data?.general_3} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 4" data={data?.general_4} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 5" data={data?.general_5} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 6" data={data?.general_6} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 7" data={data?.general_7} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 8" data={data?.general_8} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 9" data={data?.general_9} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 10" data={data?.general_10} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 11" data={data?.general_11} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 12" data={data?.general_12} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 13" data={data?.general_13} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 14" data={data?.general_14} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 15" data={data?.general_15} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 16" data={data?.general_16} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 17" data={data?.general_17} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 18" data={data?.general_18} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 19" data={data?.general_19} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 20" data={data?.general_20} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 21" data={data?.general_21} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 22" data={data?.general_22} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 23" data={data?.general_23} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 24" data={data?.general_24} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 25" data={data?.general_25} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 26" data={data?.general_26} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 27" data={data?.general_27} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 28" data={data?.general_28} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 29" data={data?.general_29} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 30" data={data?.general_30} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 31" data={data?.general_31} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 32" data={data?.general_32} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 33" data={data?.general_33} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 34" data={data?.general_34} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 35" data={data?.general_35} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 36" data={data?.general_36} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 37" data={data?.general_37} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 38" data={data?.general_38} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 39" data={data?.general_39} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="General 40" data={data?.general_40} />
                                            )}
                                        </div>
                                    </div>

                                    <div className="row full-width">
                                        <h4 className="mt-3">TU Contact Information</h4>
                                    </div>
                                    <div className="row full-width">
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Employer 1" data={data?.tu_employer1} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Employer 1 Last Update" data={data?.tu_employer1_lastUpdate} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Employer 1 Occupation" data={data?.tu_employer1_occupation} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Work Phone 1 Last Update" data={data?.tu_workPhone1_lastUpdate} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Work Phone 1 Number" data={data?.tu_workPhone1} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Work Phone 2 Last Update" data={data?.tu_workPhone2_lastUpdate} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Work Phone 2 Number" data={data?.tu_WorkPhone2} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Work Phone 3 Last Update" data={data?.tu_workPhone3_lastUpdate} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Work Phone 3 Number" data={data?.tu_workPhone3} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Home Phone 1 Last Update" data={data?.tu_homePhone1_lastUpdate} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Home Phone 1 Number" data={data?.tu_homePhone1} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Home Phone 2 Last Update" data={data?.tu_homePhone2_lastUpdate} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Home Phone 2 Number" data={data?.tu_homePhone2} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Home Phone 3 Last Update" data={data?.tu_homePhone3_lastUpdate} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Home Phone 3 Number" data={data?.tu_homePhone3} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Cell Number 1 Last Update" data={data?.tu_cellNumber1_lastUpdate} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Cell Number 1 Number" data={data?.tu_cellNumber1_number} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Cell Number 2 Last Update" data={data?.tu_cellNumber2_lastUpdate} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Cell Number 2 Number" data={data?.tu_cellNumber2_number} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Cell Number 3 Last Update" data={data?.tu_cellNumber3_lastUpdate} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Cell Number 3 Number" data={data?.tu_cellNumber3_number} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Email Address" data={data?.tu_email_address} />
                                            )}
                                        </div>

                                    </div>

                                    <div className="row full-width">
                                        <h4 className="mt-3">TU Scoring Information</h4>
                                    </div>
                                    <div className="row full-width">
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Estimated Income" data={data?.tu_estimated_income} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Collections Priority" data={data?.tu_collections_priority} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Exclusion Reason" data={data?.tu_exclusion_reason} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Score" data={data?.tu_score} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Score Band" data={data?.tu_score_band} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU AFF Band" data={data?.tu_aff_band} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Monthly Commitment" data={data?.tu_monthly_commitment} />
                                            )}
                                        </div>

                                    </div>

                                    <div className="row full-width">
                                        <h4 className="mt-3">Tu Strike Date</h4>
                                    </div>
                                    <div className="row full-width">
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Range Start Date 1" data={data?.tu_range_start_date1} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Range End Date 1" data={data?.tu_range_end_date1} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Max Pay Date 1" data={data?.tu_max_pay_date1} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Range Start Date 2" data={data?.tu_range_start_date2} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Range End Date 2" data={data?.tu_range_end_date2} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Max Pay Date 2" data={data?.tu_max_pay_date2} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Confidence Score" data={data?.tu_confidence_score} />
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {data && (
                                                <DataDisplayM label="TU Confidence Level" data={data?.tu_confidence_level} />
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </CardContent>
                            <CardActions>
                                <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                                    {/* Cancel Button */}
                                    <Button variant="outlined"
                                        onClick={handleCancelEvent}>
                                        {t('common.cancel')}
                                    </Button>

                                    &nbsp;&nbsp;
                                </div>
                            </CardActions>
                        </Card>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default CollectionReportView;
