// @ts-nocheck

import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { useTranslation } from "react-i18next";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import LeadsMain from '../../Leads/LeadsMain/LeadsMain';
import DetailPaymentReport from '../DetailPaymentReport/DetailPaymentReport';
import AgentProductivityReport from '../AgentProductivityReport/AgentProductivityReport';
import AgentActivityReport from '../AgentActivityReport/AgentActivityReport';
import PtpReport from '../PtpReport/PtpReport';
import CollectionBookReport from '../../Debtordetail/CollectionBookReport/CollectionBookReport';
import { getUserData } from '../../../../../Services/LocalStorageService';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function ReportsMain() {
    // css for module
    const classes = useStyles();

    const userData = getUserData();

    // translation
    const { t } = useTranslation();

    // ui controls
    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
    }, [])


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="mt-3">
                                {t('module.leadReport')}
                            </h2>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                        <Tab label="Search Debtors Report" />
                                        <Tab label="Detailed Payment Report " />
                                        <Tab label="Agent Productivity Report" />
                                        <Tab label="Agent Activity Report" />
                                        <Tab label="PTP Report" />
                                        {/* {userData.roleId == 1 && */}
                                            <Tab label="Collection Book Report" />
                                        {/* } */}
                                    </Tabs>
                                </Box>
                            </Box>


                            <div className="mt-4">
                                {/* Search Debtors Report */}
                                {tabValue == 0 &&
                                    <div className="row">
                                        <LeadsMain />
                                    </div>
                                }

                                {/* Detailed Payment Report */}
                                {tabValue == 1 &&
                                    <div className="row">
                                        <DetailPaymentReport />
                                    </div>
                                }

                                {/* Agent Productivity Report */}
                                {tabValue == 2 &&
                                    <div className="row">
                                        <AgentProductivityReport />
                                    </div>
                                }

                                {/* Agent Activity Report */}
                                {tabValue == 3 &&
                                    <div className="row">
                                        <AgentActivityReport />
                                    </div>
                                }

                                {/* PTP Report */}
                                {tabValue == 4 &&
                                    <div className="row">
                                        <PtpReport />
                                    </div>
                                }

                                {/* Collection Book Report */}
                                {tabValue == 5 && 
                                    <div className="row">
                                        <CollectionBookReport />
                                    </div>
                                }
                            </div>
                        </>
                    </div>

                </div>

            </Box>

        </Box>
    );
}

export default ReportsMain;
