// @ts-nocheck

import React, { useRef, useState, useEffect } from 'react';
import { useStyles } from './Styles';
import '../../../../../../index.scss';
import { RootStateOrAny, useSelector } from "react-redux";
import { post } from "../../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../../Helpers/AlertM/AlertM';
import { log } from "../../../../../../Services/LoggerService";
import { IconButton, Skeleton, Button, TextField, FormHelperText, FormControl } from "@mui/material";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import ConfirmationDialog from "../../../../../Helpers/ConfirmationDialog/ConfirmationDialog";
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { getAll } from '../../../../../../Services/GenericApiService';
import InputLabel from '@mui/material/InputLabel';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function AutomatedImporterSftp() {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    // module(s) for api
    const moduleMain = 'debtordetail/';
    const moduleCampaign = 'campaign';

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [allCampaign, setAllCampaign] = React.useState([]);
    const [selectedCampaignId, setSelectedCampaignId] = useState();

    // ui controls
    const [loading, setLoading] = React.useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    const handleSelectCampaignChange = (event) => {
        setSelectedCampaignId(event.target.value);
        setIsButtonVisible(true); // Show button if a value is selected
    };

    const handleStrikeDateBookImport = () => {
        setLoading(true);
        post(moduleMain + 'collection/stikedate/book/import')
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Strike Date Import Completed', _res.success + ' accounts were imported from Strike Date Successfully');
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
                if (_err.response.data.code == 403) {
                    // @ts-ignore
                    notifications.current.errorAlert('No new file Found on SFTP', t('common.tryAgain'));
                } else {
                    // @ts-ignore
                    notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                }
            })
    }


    // fetch Campaign
    const fetchCampaign = () => {
        setLoading(true); // show loader
        getAll(moduleCampaign)
            .then((_res: any) => {
                setAllCampaign(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const handleEarlyStageAndContact = () => {
        setLoading(true);
        post(moduleMain + 'collection/earlystageandcontact/book/import')
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Early Stage and Contact Import Completed', _res.success + ' accounts were imported from Early Stage and Contact Successfully');
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
                if (_err.response.data.code == 403) {
                    // @ts-ignore
                    notifications.current.errorAlert('No new file Found on SFTP', t('common.tryAgain'));
                } else {
                    // @ts-ignore
                    notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                }
            })
    }

    const handleCollectionBookImport = () => {
        setLoading(true);
        post(moduleMain + 'collection/upload/book',{campaignId: selectedCampaignId})
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Collection Book Import Completed', _res.success + ' accounts were imported from Collection Book Successfully');
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
                if (_err.response.data.code == 403) {
                    // @ts-ignore
                    notifications.current.errorAlert('No new file Found on SFTP', t('common.tryAgain'));
                } else {
                    // @ts-ignore
                    notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                }
            })
    }

    const handleExportForTuScoring = () => {
        setLoading(true);
        post(moduleMain + '/collection/tu/book/export')
            .then(_res => {
                setLoading(false);
                // @ts-ignore
                notifications.current.successAlert('Collection Book Export Completed');
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
                if (_err.response.data.code == 403) {
                    // @ts-ignore
                    notifications.current.errorAlert('No new file Found on SFTP', t('common.tryAgain'));
                } else {
                    // @ts-ignore
                    notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                }
            })
    }

    useEffect(() => {
        fetchCampaign()
    }, [])

    return (
        <div>
            <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg py-4 px-3"}>
                <div className="px-3 pb-1">
                    <h2 className={'mb-5'}>
                        Automated Import
                    </h2>

                    {/* Importer */}
                    <div className="mt-3">
                        <div className="row">
                            <div className="col-md-8">
                                <h4>
                                    Collection Book Import
                                </h4>
                                <p>
                                    This will pull the Collection Book from Globalscape SFTP and store it in the system.
                                </p>
                            </div>

                            <div className="col-md-4">
                                {!loading &&
                                    <>
                                        <FormControl fullWidth={true}>
                                            <InputLabel id="campaign">
                                                Campaign
                                            </InputLabel>
                                            {allCampaign.length > 0 ? (
                                                <Select
                                                    labelId="campaign"
                                                    id="campaign"
                                                    value={selectedCampaignId}
                                                    onChange={handleSelectCampaignChange}
                                                    label="campaign"
                                                >
                                                    {allCampaign.map((el: any) => (
                                                        <MenuItem value={el.id} key={el.id}>
                                                            {el.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            ) : (
                                                <>
                                                    <Select
                                                        labelId="campaign"
                                                        disabled
                                                        id="campaign"
                                                        label="campaign"
                                                    >
                                                        <MenuItem value={''}>No Data</MenuItem>
                                                    </Select>
                                                    <FormHelperText>No Data</FormHelperText>
                                                </>
                                            )}

                                            {isButtonVisible && (
                                                <ConfirmationDialog
                                                    confirmEvent={() => {
                                                        handleCollectionBookImport();
                                                    }}
                                                    type="confirm"
                                                    showButton={true}
                                                    title="Collection Book Import"
                                                    body="Are you sure you want to start Collection Book Import?"
                                                    buttonText="Start Collection Book Import"
                                                />
                                            )}
                                        </FormControl>
                                    </>
                                }

                                {loading &&
                                    <Skeleton variant="rounded" height={60} />
                                }
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-8">
                                <h4>
                                    Export Collection Book For TU Scoring
                                </h4>
                                <p>
                                    This will put an exported file on TU SFTP for scoring
                                </p>
                            </div>

                            <div className="col-md-4">
                                {!loading &&
                                    <ConfirmationDialog
                                        confirmEvent={(_itemIdx) => {
                                            handleExportForTuScoring()
                                        }}
                                        type={'confirm'}
                                        showButton={true}
                                        title={'Collection Book Export for TU Scoring'}
                                        body={'Are you sure you want to start Collection Book Export for TU Scoring?'}
                                        buttonText={'Collection Book Export for TU Scoring'} />
                                }

                                {loading &&
                                    <Skeleton variant="rounded" height={60} />
                                }
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-8">
                                <h4>
                                    Import Strike Date
                                </h4>
                                <p>
                                    This will pull the Strike Date XL from Globalscape SFTP and update collection book records.
                                </p>
                            </div>

                            <div className="col-md-4">
                                {!loading &&
                                    <ConfirmationDialog
                                        confirmEvent={(_itemIdx) => {
                                            handleStrikeDateBookImport()
                                        }}
                                        type={'confirm'}
                                        showButton={true}
                                        title={'Strike Date Import'}
                                        body={'Are you sure you want to start Strike Date Import?'}
                                        buttonText={'Start Strike Date Import'} />
                                }

                                {loading &&
                                    <Skeleton variant="rounded" height={60} />
                                }
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-8">
                                <h4>
                                    Import Early Stage and Contacts
                                </h4>
                                <p>
                                    This will pull the Early Stage and Contacts XL from Globalscape SFTP and update collection book records.
                                </p>
                            </div>

                            <div className="col-md-4">
                                {!loading &&
                                    <ConfirmationDialog
                                        confirmEvent={(_itemIdx) => {
                                            handleEarlyStageAndContact()
                                        }}
                                        type={'confirm'}
                                        showButton={true}
                                        title={'Strike Date Import'}
                                        body={'Are you sure you want to start Early Stage and Contact Import?'}
                                        buttonText={'Start Early Stage and Contact Import'} />
                                }

                                {loading &&
                                    <Skeleton variant="rounded" height={60} />
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Alerts */}
            <AlertM ref={notifications} />

        </div>
    );
}

export default AutomatedImporterSftp;
